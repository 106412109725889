.custom-card-layout {
    display: grid;
    grid-template-areas:
      "pos1 pos1"
      "pos2 pos3";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .position-1 {
    grid-area: pos1;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }
  
  .position-2 {
    grid-area: pos2;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    text-align: center;
  }
  
  .position-3 {
    grid-area: pos3;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    text-align: center;
  }
  